<template>
  <div class='bg-gray-900' ref='sessionVimeoPlayerContainer'>
    <div v-if='showingMessageBox'
         class='absolute text-sm text-center rounded z-20 w-4/5 bg-gray-100 p-4 lg:p-8'>
      <div class='p-2 lg:p-8 text-center leading-normal whitespace-pre-line'>
        {{ selectedSessionConfigAttendingMessage }}
      </div>
      <button
        class='h-12 pl-12 pr-10 rounded uppercase text-white font-semibold hover:shadow-md border hover:border-indigo-200 flex flex-row items-center mx-auto gap-x-2'
        :style='startLiveButtonStyle'
        @click='playLiveVideo'>
        {{ selectedSessionConfigAttendingButtonText }}
        <play-icon/>
      </button>
    </div>
    <div id='vimeo-player' class='vimeo-player'></div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import {PlayIcon}               from '@vue-hero-icons/outline'
import Player from '@vimeo/player'
import dayjs                    from 'dayjs'
import WatchedVideoCacheHelpers from '@/utils/watched-video-cache-helpers'
import { createStoreon }        from 'storeon'
import { crossTab }             from '@storeon/crosstab'

export default {
  name: 'LiveVideoContentVimeo',
  components: {
    PlayIcon
  },
  data () {
    return {
      sessionVimeoPlayerContainerWidth: 0,
      getLiveVideoPlayedTimes: null,
      showingMessageBox: true,
      liveVideoPlayer: null,
      playStartTime: null,
      videoTracker: null,
      storeViewingVideoCount: null,
      increaseViewingVideoCount: null,
      playedTimes: null,
    }
  },
  watch: {
    contentID: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal != oldVal && !this.showingMessageBox) {
          console.log('new content ID')
          this.$nextTick(() => {
            if (this.liveVideoPlayer) {
              this.liveVideoPlayer.destroy()
            }
            this.liveVideoPlayer = new Player('vimeo-player', this.videoOptions)
            this.liveVideoPlayer.ready().then(() => {
              this.resizePlayer()
            })
            this.liveVideoPlayer.on('pause', this.stopWatchSession)
            this.liveVideoPlayer.on('playing', this.startWatchSession)
            this.liveVideoPlayer.on('fullscreenchange', this.fullscreenUpdate)
            this.liveVideoPlayer.on('error', (error) => {
              console.error('error', error)
              this.promptRefreshPage()
            })
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
      'showingEventId',
    ]),
    ...mapGetters('sessions', [
      'selectedSessionCurrentContent',
      'selectedSessionConfigAttendingMessage',
      'selectedSessionConfigAttendingButtonText',
    ]),
    cacheKey () {
      return WatchedVideoCacheHelpers.encodeCacheKey({
        eventID: this.showingEventId,
        sessionID: this.sessionID,
        contentID: this.contentID,
        startTime: this.playStartTime
      })
    },
    trackWatchedVideoParams () {
      return {
        event_id: this.showingEventId,
        session_id: this.sessionID,
        content_id: this.contentID,
        video_player: 'vimeo',
        start_time: this.playStartTime,
        played_times: this.playedTimes
      }
    },
    contentID () {
      return this.selectedSessionCurrentContent.id
    },
    sessionID () {
      return this.$route.query.session_id
    },
    autoplay () {
      return this.$route.query.autoplay
    },
    startLiveButtonStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    videoOptions () {
      return {
        autoplay: true,
        responsive: true,
        title: false,
        byline: false,
        speed: false,
        url: this.selectedSessionCurrentContent.data.sourceUrl,
      }
    },
    sessionVimeoPlayerContainerHeight () {
      return (this.sessionVimeoPlayerContainerWidth &&  this.selectedSessionCurrentContent.height && this.selectedSessionCurrentContent.width)
             ? Math.max(parseInt((this.sessionVimeoPlayerContainerWidth) * (this.selectedSessionCurrentContent.height / this.selectedSessionCurrentContent.width)), 360)
             : this.sessionVimeoPlayerContainerWidth * (9/16) // assuming this is the standard vimeo aspect ratio
    },
  },
  methods: {
    ...mapActions('sessions', [
      'startWatchSession',
      'stopWatchSession',
    ]),
    ...mapActions('watchedVideos', [
      'trackWatchedVideo',
    ]),
    ...mapMutations([
      'setIsFullscreenVideo',
    ]),
    ...mapActions('userActions', [
      'createUserAction'
    ]),
    playLiveVideo () {
      if (this.liveVideoPlayer) {
        this.liveVideoPlayer.destroy()
      }
      this.liveVideoPlayer = new Player('vimeo-player', this.videoOptions)
      this.liveVideoPlayer.ready().then(() => {
        this.resizePlayer()
      })
      this.liveVideoPlayer.on('pause', this.stopWatchSession)
      this.liveVideoPlayer.on('playing', this.startWatchSession)
      this.liveVideoPlayer.on('error', (error) => {
        console.error('error', error)
        this.promptRefreshPage()
      })
      this.initStoreViewingVideoCount()
      this.playStartTime = dayjs().format()
      this.showingMessageBox = false
      this.getLiveVideoPlayedTimes = setInterval(() => {
        this.liveVideoPlayer.getPlayed().then((playedData) => {
          // playedData is time range array
          WatchedVideoCacheHelpers.queueCache({cacheKey: this.cacheKey, playedArray: playedData})
          this.playedTimes = playedData.map((array) => array.map((number) => number - playedData[0][0]))
          //update trackWatchedVideoParams played_times every 5 seconds
        }).catch((error) => {
          // an error occurred
          console.error('error', error)
        })
      }, 5000)
      this.videoTracker = setInterval(this.submitTrackWatchedVideo, 30000)
      this.$emit('enter-live-session')
      this.createUserAction({
        userActionName: 'click_live_session_video_start_button',
        userActionData: {
          event_id: this.showingEventId,
          session_id: this.sessionID,
          content_id: this.contentID,
          video_player: 'vimeo'
        }
      })
    },
    submitTrackWatchedVideo () {
      // this.liveVideoPlayer.error(3) // to trigger decode error manually
      this.trackWatchedVideo(this.trackWatchedVideoParams)
    },
    initStoreViewingVideoCount () {
      this.increaseViewingVideoCount = store => {
        store.on('@init', () => ({ viewingVideoCount: 0 }))
        store.on('increase', ({ viewingVideoCount }) => ({ viewingVideoCount: viewingVideoCount + 1 }))
      }
      this.storeViewingVideoCount = createStoreon([
        this.increaseViewingVideoCount,
        crossTab()
      ])
      this.storeViewingVideoCount.dispatch('increase')
      this.storeViewingVideoCount.on('@changed', (store) => {
        if (store.viewingVideoCount > 1) {
          if (this.liveVideoPlayer) {
            this.liveVideoPlayer.dispose()
          }
          this.$alert('You have started a video on another tab. This tab will be redirected to the programs page after 5 seconds', {
            confirmButtonText: 'Close',
            type: 'warning'
          })
          setTimeout(() => {
            window.open('Programs', '_self')
          }, 5000)
        }
      })
    },
    fullscreenUpdate (resp) {
      this.setIsFullscreenVideo(resp.fullscreen)
    },
    getContainerWidth () {
      if (this.showSessionQuestionsBox && this.$refs.sessionVimeoPlayerContainer.clientWidth >= 1024) {
        this.sessionVimeoPlayerContainerWidth = this.$refs.sessionVimeoPlayerContainer.clientWidth * 0.7
      } else {
        this.sessionVimeoPlayerContainerWidth = this.$refs.sessionVimeoPlayerContainer.clientWidth
      }
      this.resizePlayer()
    },
    resizePlayer () {
      let player = document.querySelector('#vimeo-player iframe')
      if (player) {
        player.width = `${this.sessionVimeoPlayerContainerWidth}px`
        player.height = `${this.sessionVimeoPlayerContainerHeight}px`
      }
    },
    promptRefreshPage () {
      this.$confirm('There was a problem with the video. Please try refreshing the page.','Notice', {
        confirmButtonText: 'Refresh',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$router.go(0)
      })
    },
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getContainerWidth)
    clearInterval(this.getLiveVideoPlayedTimes)
    clearInterval(this.videoTracker)
    this.stopWatchSession()
    if (this.liveVideoPlayer) {
      this.liveVideoPlayer.destroy()
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.getContainerWidth)
      this.getContainerWidth()
    })
  }
}
</script>
